import React from 'react';
import PropTypes from 'prop-types';

import ItemDataFetcher from '../../api/object-admin/ItemDataFetcher';
import SelectItemType from '../EditForm/Inputs/SelectItemType';
import TargetSelect from '../Utils/TargetSelect';
import UIHolidayTeaserBox from '../UIHolidayTeaserBox';

import './styles.scss';

/**
 * @visibleName 3 HolidayShop variable Teaser Boxes
 */
class HolidayShopTeaserBoxListThree extends React.Component {
    render() {
        const {
            itemType1, itemId1, url1, targetUrl1,
            itemType2, itemId2, url2, targetUrl2,
            itemType3, itemId3, url3, targetUrl3,

            imgSrc1, imgAlt1, imgTitle1, title1, text1, linkText1,
            imgSrc2, imgAlt2, imgTitle2, title2, text2, linkText2,
            imgSrc3, imgAlt3, imgTitle3, title3, text3, linkText3,

            language, season,
        } = this.props;
        const className = 'holiday-teaser-box-listing-three';
        return (
            <div className={className}>
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-col-xl-4 g-col-lg-2 g-col-md-2 g-col-sm-2">
                            {itemType1 ? (
                                <ItemDataFetcher
                                    itemType={itemType1}
                                    itemId={itemId1}
                                    language={language}
                                    season={season}
                                >
                                    <UIHolidayTeaserBox
                                        target={'_self'}
                                        url={url1}
                                        modificators={['yellow-button']}
                                    />
                                </ItemDataFetcher>
                            ) : (
                                <UIHolidayTeaserBox
                                    imgSrc={imgSrc1}
                                    imgAlt={imgAlt1}
                                    imgTitle={imgTitle1}
                                    title={title1}
                                    text={text1}
                                    linkText={linkText1}
                                    url={url1}
                                    target={targetUrl1}
                                    modificators={['yellow-button']}
                                />
                            )}
                        </div>

                        <div className="g-col-xl-4 g-col-lg-2 g-col-md-2 g-col-sm-2">
                            {itemType2 ? (
                                <ItemDataFetcher
                                    itemType={itemType2}
                                    itemId={itemId2}
                                    language={language}
                                    season={season}
                                >
                                    <UIHolidayTeaserBox
                                        target={'_self'}
                                        url={url2}
                                        modificators={['yellow-button']}
                                    />
                                </ItemDataFetcher>
                            ) : (
                                <UIHolidayTeaserBox
                                    imgSrc={imgSrc2}
                                    imgAlt={imgAlt2}
                                    imgTitle={imgTitle2}
                                    title={title2}
                                    text={text2}
                                    linkText={linkText2}
                                    url={url2}
                                    target={targetUrl2}
                                    modificators={['yellow-button']}
                                />
                            )}
                        </div>

                        <div className="g-col-xl-4 g-col-lg-2 g-col-md-2 g-offset-md-1 g-col-sm-2">
                            {itemType3 ? (
                                <ItemDataFetcher
                                    itemType={itemType3}
                                    itemId={itemId3}
                                    language={language}
                                    season={season}
                                >
                                    <UIHolidayTeaserBox
                                        target={'_self'}
                                        url={url3}
                                        modificators={['yellow-button']}
                                    />
                                </ItemDataFetcher>
                            ) : (
                                <UIHolidayTeaserBox
                                    imgSrc={imgSrc3}
                                    imgAlt={imgAlt3}
                                    imgTitle={imgTitle3}
                                    title={title3}
                                    text={text3}
                                    linkText={linkText3}
                                    url={url3}
                                    target={targetUrl3}
                                    modificators={['yellow-button']}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

HolidayShopTeaserBoxListThree.formFields = Object.assign({}, {
    itemType1: <SelectItemType id={'itemType1'} options={['activity']} />,
    itemId1: <input />,
    url1: <input />,
    targetUrl1: <TargetSelect />,

    imgSrc1: <input />,
    imgAlt1: <input />,
    imgTitle1: <input />,
    title1: <input />,
    linkText1: <input />,
    text1: <input />,
    //
    itemType2: <SelectItemType id={'itemType2'} options={['activity']} />,
    itemId2: <input />,
    url2: <input />,
    targetUrl2: <TargetSelect />,

    imgSrc2: <input />,
    imgAlt2: <input />,
    imgTitle2: <input />,
    title2: <input />,
    linkText2: <input />,
    text2: <input />,
    //
    itemType3: <SelectItemType id={'itemType3'} options={['activity']} />,
    itemId3: <input />,
    url3: <input />,
    targetUrl3: <TargetSelect />,

    imgSrc3: <input />,
    imgAlt3: <input />,
    imgTitle3: <input />,
    title3: <input />,
    linkText3: <input />,
    text3: <input />,
});

HolidayShopTeaserBoxListThree.propTypes = {
    // item1
    itemType1: PropTypes.string,
    itemId1: PropTypes.string,
    url1: PropTypes.string,
    targetUrl1: PropTypes.string,
    imgSrc1: PropTypes.string,
    imgAlt1: PropTypes.string,
    imgTitle1: PropTypes.string,
    title1: PropTypes.string,
    text1: PropTypes.string,
    linkText1: PropTypes.string,
    // item2
    itemType2: PropTypes.string,
    itemId2: PropTypes.string,
    url2: PropTypes.string,
    targetUrl2: PropTypes.string,
    imgSrc2: PropTypes.string,
    imgAlt2: PropTypes.string,
    imgTitle2: PropTypes.string,
    title2: PropTypes.string,
    text2: PropTypes.string,
    linkText2: PropTypes.string,
    // item3
    itemType3: PropTypes.string,
    itemId3: PropTypes.string,
    url3: PropTypes.string,
    targetUrl3: PropTypes.string,
    imgSrc3: PropTypes.string,
    imgAlt3: PropTypes.string,
    imgTitle3: PropTypes.string,
    title3: PropTypes.string,
    text3: PropTypes.string,
    linkText3: PropTypes.string,
    // other
    language: PropTypes.string,
    season: PropTypes.string,
};

HolidayShopTeaserBoxListThree.defaultProps = {
    // item1
    itemType1: '',
    itemId1: '',
    imgSrc1: '',
    imgAlt1: '',
    imgTitle1: '',
    title1: '',
    text1: '',
    linkText1: '',
    url1: '',
    targetUrl1: '_self',

    // item2
    itemType2: '',
    itemId2: '',
    imgSrc2: '',
    imgAlt2: '',
    imgTitle2: '',
    title2: '',
    text2: '',
    linkText2: '',
    url2: '',
    targetUrl2: '_self',

    // item3
    itemType3: '',
    itemId3: '',
    imgSrc3: '',
    imgAlt3: '',
    imgTitle3: '',
    title3: '',
    text3: '',
    linkText3: '',
    url3: '',
    targetUrl3: '_self',

    // other
    language: 'de',
    season: 'winter',
};

export default HolidayShopTeaserBoxListThree;
